import React, {
    useState
} from 'react'

import { Modal } from 'react-bootstrap'
import add from '../Assets/add.jpeg'
import pic from '../Assets/EVStation_Cam11eyeview_2505.png'

// import logo from '../Assets/ezgif.com-gif-maker.gif'


const Home = () => {
    const [show, setShow] = useState(true);



    return (
        <div className='container'>
            <Modal

                size="lg"
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Dialog style={{ backgroundColor: "transparent" }}  >

                    <Modal.Body>
                        <img style={{ maxWidth: "-webkit-fill-available" }} className='img' src={add} alt="" />
                    </Modal.Body>



                </Modal.Dialog>
            </Modal>
            <div>
                {/* <img className='runing-car' src={logo} alt="logo" /> */}
            </div>

            <img className='pic' src={pic} alt="pic" />
            <h1>Electric Vechicle Pump Services</h1><br /><h1> Electric vehicle chargers
            </h1>
            <div>We make technology accessible!</div>
            <hr />
            <div className='container'>
                <h2>All public <a style={{ color: "green" }} href>charging points</a>
                    <br />in your smartphone app</h2>
                <span>Now,</span> <br />
                <span>track a nearest charging stations which is fully automated with availability status.</span> <br />
                SMake online payment, etc. for charging of your electric vehicle.
            </div>
            <div></div>
            <hr />
            <div className='hi'>
                {/* <div className='hi-item'>
                   <img src='' alt='' />
                   <img src='' alt='' />
                   <img src='' alt='' />
                   <img src='' alt='' />
                   <img src='' alt='' />

                </div> */}

            </div>


        </div>
    )
}

export default Home