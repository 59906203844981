import React from 'react'

const Services = () => {
    return (
        <div className='container '>

            <div >
                <div style={{margin:"auto"}} className='' >
                    <h2 className='text-center'>Our Services</h2><hr />
                    <h4 className='text-center'>Technical Specifications AC CHARGER

                    </h4>
                    <br />
                    <table >
                        <td>
                            <tr className='border1'>
                                Power Output
                                <td>
                                    <tr>DC Output Voltage Running</tr>
                                    <tr>Output Current</tr>
                                    <tr>Power Rating</tr>
                                    <tr>Connector</tr>
                                    <tr>Number of Connector</tr>
                                    <tr>Efficiency</tr>

                                </td>
                                <td>
                                    <tr>220-440 VAC</tr>
                                    <tr>100A Max</tr>
                                    <tr>3.3-44 kW</tr>
                                    <tr>Type 2 Socket</tr>
                                    <tr>1 (or) 2</tr>
                                    <tr>≥95%</tr>

                                </td>

                            </tr>

                            <tr>Power Input
                                <td>
                                    <tr>Input Voltage</tr>
                                    <tr>Input Frequency</tr>
                                    <tr>THD</tr>
                                    <tr>Power Factor</tr>


                                </td>
                                <td>
                                    <tr>
                                        <tr>3Φ415V (± 6% and -10%)</tr>
                                        <tr>3Phase, 5 Wire AC System (3Ph+N+E)</tr>
                                    </tr>
                                    <tr>50Hz ± 5Hz</tr>
                                    <tr>≤ 5% @ Nominal Voltage</tr>
                                    <tr>≥ 0.99 (Full Load)</tr>


                                </td>
                            </tr>
                            <tr>Protection & Safety
                                <td>
                                    <tr>Safety Parameters</tr>



                                </td>
                                <td>

                                    <tr>Over Current, Under Voltage,</tr>
                                    <tr>Over Voltage, Surge Protection, Short </tr>
                                    <tr>Circuit, Over Temperature</tr>


                                </td>
                            </tr>
                            <tr>User Interface & Control
                                <td>
                                    <tr>Display</tr>
                                    <tr>Support Language</tr>
                                    <tr>Push Button</tr>
                                    <tr>Visual Indication</tr>
                                    <tr>User Authentication</tr>


                                </td>
                                <td>
                                    <tr>
                                        7 or 10 inch LCD Touch Screen
                                        32 Inch Display for Advertisement
                                    </tr>
                                    <tr>English</tr>
                                    <tr>Emergency Stop Switch (Red)</tr>
                                    <tr>Error Indicator; Presence of<br />Input Supply, State of Charger Indicator</tr>
                                    <tr>
                                        <td>
                                            <tr>ISO/IEC 14443A RFID/QR Code</tr>
                                            <tr>Customizable</tr>
                                        </td>

                                    </tr>

                                </td>
                            </tr>
                            <tr>Payments
                                <td>
                                    Payment

                                </td>
                                <td>Smart Card, QR/OTP/APP Server based</td>
                            </tr>
                            <tr>Communication
                                <td>
                                    <tr>B/ W EVSE and Vehicle</tr>
                                    <tr>B/ W Charger and Central Server</tr>
                                </td>
                                <td>
                                    <tr>CAN </tr>
                                    <tr>OCPP V 1.6 or 2.0 (Optional)</tr>
                                    <tr>Ethernet, Wi-Fi, GSM/GPS/GPRS
                                        Modem & Bluetooth
                                    </tr>
                                </td>
                            </tr>
                            <tr>Mechanical
                                <td>
                                    <tr>Ingress Protection</tr>
                                    <tr>Cooling</tr>
                                    <tr>Charging Cable Length</tr>
                                </td>
                                <td>
                                    <tr>IP54</tr>
                                    <tr>Forced Air Cooling</tr>
                                    <tr>5Meters / Customizable</tr>
                                </td>
                            </tr>
                            <tr>Environmental
                                <td>
                                    <tr>Operating Temperature</tr>
                                    <tr>Humidity (Non-Considering)</tr>
                                    <tr>Storage Temperature</tr>
                                    <tr>Altitude</tr>
                                </td>
                                <td>
                                    <tr>-20°C to 75°C</tr>
                                    <tr>0 to 95%</tr>
                                    <tr>-20°C to 80°C</tr>
                                    <tr>Upto 3000 Meters</tr>
                                </td>
                            </tr>
                        </td>
                        <td>
                            <tr>

                            </tr>
                        </td>
                    </table>
                </div>
                <br />
                <hr />
                <div className='line'></div>
                <div className='' >
                    <h4 className='text-center'>Technical Specifications DC FAST CHARGERS
                    </h4><br />
                    <table>
                        <td>
                            <tr>
                                Power Output
                                <td>
                                    <tr>DC Output Voltage Running</tr>
                                    <tr>Output Current</tr>
                                    <tr>Power Rating</tr>
                                    <tr>Connector</tr>
                                    <tr>Number of Connector</tr>
                                    <tr>Efficiency</tr>

                                </td>
                                <td>
                                    <tr>50-1000 Vdc</tr>
                                    <tr>300A Max</tr>
                                    <tr>30-350kW</tr>
                                    <tr>CHAdeMO / GB/ T / CCS2 </tr>
                                    <tr>1 (or) 2</tr>
                                    <tr>≥95%</tr>

                                </td>

                            </tr>

                            <tr>Power Input
                                <td>
                                    <tr>Input Voltage</tr>
                                    <tr>Input Frequency</tr>
                                    <tr>THD</tr>
                                    <tr>Power Factor</tr>


                                </td>
                                <td>
                                    <tr>
                                        <tr>3Φ415V (± 6% and -10%)</tr>
                                        <tr>3Phase, 5 Wire AC System (3Ph+N+E)</tr>
                                    </tr>
                                    <tr>50Hz ± 5Hz</tr>
                                    <tr>≤ 5% @ Nominal Voltage</tr>
                                    <tr>≥ 0.99 (Full Load)</tr>


                                </td>
                            </tr>
                            <tr>Protection & Safety
                                <td>
                                    <tr>Safety Parameters</tr>



                                </td>
                                <td>

                                    <tr>Over Current, Under Voltage,</tr>
                                    <tr>Over Voltage, Surge Protection, Short </tr>
                                    <tr>Circuit, Over Temperature</tr>


                                </td>
                            </tr>
                            <tr>User Interface & Control
                                <td>
                                    <tr>Display</tr>
                                    <tr>Support Language</tr>
                                    <tr>Push Button</tr>
                                    <tr>Visual Indication</tr>
                                    <tr>User Authentication</tr>


                                </td>
                                <td>
                                    <tr>
                                        7 or 10 inch LCD Touch Screen
                                        32 Inch Display for Advertisement
                                    </tr>
                                    <tr>English</tr>
                                    <tr>Emergency Stop Switch (Red)</tr>
                                    <tr>Error Indicator; Presence of<br />Input Supply, State of Charger Indicator</tr>
                                    <tr>
                                        <td>
                                            <tr>ISO/IEC 14443A RFID/QR Code</tr>
                                            <tr>Customizable</tr>
                                        </td>

                                    </tr>

                                </td>
                            </tr>
                            <tr>Payments
                                <td>
                                    Payment

                                </td>
                                <td>Smart Card, QR/OTP/APP Server based</td>
                            </tr>
                            <tr>Communication
                                <td>
                                    <tr>B/ W EVSE and Vehicle</tr>
                                    <tr>B/ W Charger and Central Server</tr>
                                </td>
                                <td>
                                    <tr>CAN (CHAdeMO/GB/ T), PLC (CCS2)</tr>
                                    <tr>OCPP V 1.6 or 2.0 (Optional)</tr>
                                    <tr>Ethernet, Wi-Fi, GSM/GPS/GPRS
                                        Modem & Bluetooth
                                    </tr>
                                </td>
                            </tr>
                            <tr>Mechanical
                                <td>
                                    <tr>Ingress Protection</tr>
                                    <tr>Cooling</tr>
                                    <tr>Charging Cable Length</tr>
                                </td>
                                <td>
                                    <tr>IP54</tr>
                                    <tr>Forced Air Cooling</tr>
                                    <tr>5Meters / Customizable</tr>
                                </td>
                            </tr>
                            <tr>Environmental
                                <td>
                                    <tr>Operating Temperature</tr>
                                    <tr>Humidity (Non-Considering)</tr>
                                    <tr>Storage Temperature</tr>
                                    <tr>Altitude</tr>
                                </td>
                                <td>
                                    <tr>-20°C to 75°C</tr>
                                    <tr>0 to 95%</tr>
                                    <tr>-20°C to 80°C</tr>
                                    <tr>Upto 3000 Meters</tr>
                                </td>
                            </tr>
                        </td>

                    </table>
                    <br />
                </div>
            </div>
        </div>
    )
}

export default Services