import React from 'react'

// import logo from '../Assets/ezgif.com-gif-maker.gif'

const About = () => {
  return (
    <div  className="container">
      <h1>About Us</h1>

      {/* <img className='runingcar' src={logo} alt="logo" /> */}
    
      <br></br>
      <br />
      <br />

      <div>
        EV Pumps provides IoT-based Smart Electric Vehicle Charging
        Infrastructure Solutions as a partner in conceptualising and
        implementing technology-driven business transformation initiatives.
        We offer Dynamic Charging Solutions where Business and Technology
        Strategies converge to improve Productivity and Profitability by
        incorporating a low-risk Global Delivery Model (GDM) to
        accelerate schedules with a high degree of Time and Cost
        Predictability.
        Electric vehicles, like those powered by fossil fuels, must be refuelled. Their
        energy source is electricity. We cater to electric vehicle owners' ever-changing needs. We offer a
        hassle-free and dependable charging service (EVs) in both CAPEX and OPEX models for all types of
        electric vehicles.
        The group's key strengths are in technology, government regulations, power purchase agreements,
        and the CAPEX and OPEX model of EV Charge Point execution.
      </div>
      <br />
      <br />
      <h1>Our Charger</h1>
      
      {/* <img className='runingcar' src={logo} alt="logo" /> */}

      <br></br>
      <br />
      <br />
      <div>
        <ul>
          <li>
            Our Charging Station capable of charging all electric vehicles with AC / DC Chargers compliant with
            CCS 2, GB/T, and CHAdeMO charging standards, with a maximum DC output power of 350kW.
          </li>
          <li>
            Following user identification, simply select the charging standard compatible with your vehicle and
            connect the charger's output plug to the EV for a fast, secure, and proven charging process.

          </li>
          <li>
            The
            charging status of the battery is displayed, and the charging cycle can either finish on its own or be terminated
            by a user command.

          </li>
          <li>
            Different output options, such as the basic single DC output, dual DC output, triple DC+AC outputs or
            Multiple AC output, are available in a single cabinet or bundled with a wired charging interface kiosk.

          </li>
        </ul>



      </div>
      <div>
      <br />
      <br />
        <h1>
          Feature
        </h1>
        {/* <img className='runingcar' src={logo} alt="logo" /> */}
     
      <br></br>
      <br />
      <br />
        <ul>
          <li>
            Built-in safety measures
          </li>
          <li>
            Large User friendly interface
          </li>
          <li>
            Flexible multi-protocol design
          </li>
          <li>
            TYPE2 AC Charger 3.3-44 Kw
          </li>
          <li>
            DC CCS2, GB/T, and CHAdeMO Charger
          </li>
          <li>
            DC CSS2, GB/T, and CHAdeMO Charger
          </li>
          <li>
            OptionalIOCPP1.6/2.0
          </li>
          <li>
            WIFI, 4G,GSM Optional Durable enclosure
          </li>


          <li>
            Remote Data management and metering options

          </li>
          <li>
            RFID / Smart Card / QR Code Based Authentication
          </li>
          <li>
            Easily Upgradable
          </li>
        </ul>
      </div>
    </div>
  )
}

export default About