import React from 'react'
import logo from '../Assets/contact.png'

// import name from '../Assets/name only Horizontal_transperant_logo_5208x1242px-.png'

const Contacts = () => {
  return (
    <div className='container'>
      <h2>Contact Us</h2>
      <div className='row'>
        <div className='col'>
          <div className='d-flex justify-content-start logo'>
            <img src={logo} alt="logo" />
          </div>
          <h1>EV PUMP SERVICES PVT LTD</h1>
          <div>#212, SAI TRADE CENTER,
            RAILWAY STATION ROAD,
            AURANGABAD-431 005</div>
          <div>Contact: +91 77070 58080</div>
          <div>email: info@evpump.co.in</div>
          <div>web: www.evpump.co.in</div>


        </div>
        <div style={{margin: "45px"}} className='col form'>
          <form action="mailto:info@evpump.co.in" method="post" enctype="text/plain">
            <div>
              <div>Name</div>
              <input type='name' name="name" placeholder='Enter Your Full Name' />
            </div> <div>
              <div>Mobile No.</div>
              <input type='tel' name="Mobile" placeholder='Enter Your Mobile Number' />
            </div><div>
              <div>Email</div>
              <input type='mail' name="Email" placeholder='Enter Your Email' />
            </div>   <div>
              <div>Massage</div>
              <input style={{ width: "16rem", height: "8rem" }} name="massage" placeholder='Write Your Massage' />
            </div> <br />
            <div>
              <input className='btn btn-secondary' type='submit' value="Send" />
              
              
            </div>
          </form>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  )
}

export default Contacts