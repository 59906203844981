import './App.css';

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import About from './Components/About';
import Contacts from './Components/Contacts';
import Footer from './Components/Footer';
import Header from './Components/Header';
import Home from './Components/Home';
import NotFound from './Components/NotFound';
import Privacy from './Components/Privacy';
import Services from './Components/Services';

function App() {
  return (
   <div className='container-fluid'>
   <Router>
     <Header />
     <Routes>
       <Route path='/' element={<Home />} />
       <Route path='/home' element={<Home />} />
       <Route path='/privacy' element={<Privacy />} />
       <Route path='/services' element={<Services />} />
       <Route path='/contact' element={<Contacts />} />
       <Route path='/about' element={<About />} />
       <Route path='*' element={<NotFound />} />

     </Routes>
     <Footer />
   </Router>
   </div>
  );
}

export default App;
