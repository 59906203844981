import { Link } from 'react-router-dom'
import { Nav } from 'react-bootstrap'
import React from 'react'
import logo from '../Assets/favicon.png'
import name from '../Assets/name only Horizontal_transperant_logo_5208x1242px-.png'

const Footer = () => {
  return (
    <div className='bg-light footer '>
      <div  className='container '>
        <div className=' row'>
          <div className='col'>
            <div className='logo1'>
              <div className='d-flex justify-content-start logo'>
                <img className=" img-fluid" src={logo} alt="logo" />
                <img className="img-fluid" src={name} alt="name" />
              </div>

              <h4>EV PUMP SERVICES PRIVATE LIMITED</h4>
              <div># 212, Sai Trade Center, Railway Station Road, Aurangabad-431005</div>
              <div>Email: info@evpump.co.in</div>
              <div>Web: www.evpump.co.in</div>
              <div>contact No.: +91 77070 58080</div>
            </div>

            <br />


            <div>Copyright © 2022 EV Pump Services - All Rights Reserved.</div>
            <br />



          </div>
          <div style={{padding: "30px"}} className='col'>
            <Nav.Link ><Link className=' nav-link' to='/privacy' >Privacy Policy</Link></Nav.Link>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Footer