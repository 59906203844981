import { Container, Nav, Navbar } from 'react-bootstrap'

import { Link } from 'react-router-dom'
import React from 'react'
import logo from '../Assets/favicon.png'
import name from "../Assets/name only Horizontal_transperant_logo_5208x1242px-.png"

const Header = () => {

    return (
        <div className=' shadow p-3 mb-5 bg-white rounded' >

            <div>
                <Navbar bg="light" expand="lg">
                    <Container>
                        <Navbar.Brand className="justify-content-start">
                            <div className='d-flex logo'>
                            <img src={logo} alt="logo" />
                            <img className='logo1' src={name} alt="name" />
                            </div>
                           
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
                            <div >
                            <Nav className="me-auto ">

                                <Nav.Link ><Link className=' nav-link' to='/home' >Home</Link></Nav.Link>
                                <Nav.Link ><Link className=' nav-link' to='/about' >About Us</Link></Nav.Link>
                                <Nav.Link ><Link className=' nav-link' to='/services' >Services</Link></Nav.Link>
                                
                                <Nav.Link ><Link className=' nav-link' to='/contact' >Contact Us</Link></Nav.Link>
                               
                                <Nav.Link> <div className='join'><p className='p2'>JOIN US REVOLUTION TO</p><p className='p3'>Drive the change</p></div></Nav.Link>
                                
                            </Nav>
                            </div>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
            {/* <Link to='/home' >Home</Link>
        <Link to='/services' >Services</Link>
        <Link to='/about' >About Us</Link>
        <Link to='/contact' >Contact Us</Link>
        <Link to='/privacy' >Privacy Policy</Link> */}
        </div>
    )
}

export default Header